<template>
  <section>

    <b-row>
      <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">

        <stat
  
          :variant="stat.variant"
          :icon="stat.icon"
          :name="stat.name"
          :value="stat.value"
          :unit="stat.unit"
        ></stat>
      </b-col>
    </b-row>

    <b-row>
    <b-col>
      <bruna-element-loader :dataUrl="cv12FetPredictionsChart">
      </bruna-element-loader>
    </b-col>
  </b-row> 

  <b-row>
    <b-col>
      <bruna-element-loader :dataUrl="cv12FeDttPredictionsChart">
      </bruna-element-loader>
    </b-col>
  </b-row> 

  <b-row>
    <b-col>
      <bruna-element-loader :dataUrl="cv12FeMagPredictionsChart">
      </bruna-element-loader>
    </b-col>
  </b-row>   


    <b-row>
      <b-col sm="12">
        <ag-grid-table
          ref="grid-historico-runs"
          :debug="true"
          :configUrl="CV12historicoComparePredictionsConfig"
          :dataUrl="CV12historicoComparePredictionsData"
          @gridReady="onGridReady"
          @getRowsLoaded="onGetRowsLoaded"
          :helper="true"
        >
          
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
      
      <script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import Stat from '@/views/clients/base/components/Stat.vue'

import useApiServices from '@/services/useApiServices.js';

import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'




import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Stat,

    BOverlay,
    BSpinner,

    BrunaElementLoader





  },

  methods: {

    debugChange(value)
    {

      console.log(value)

      let decoded = JSON.parse(value)





    },





    onGridReady(gridApi, gridColumnApi, params)
    {

      this.gridApi = gridApi

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })



    },

    onGetRowsLoaded(gridApi, gridColumnApi, params)
    {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })



    },




  },

  mounted()
  {

    axios.request({
      method: "get",
      url: useApiServices.cv12Stats,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) =>
      {
        this.stats = response.data
      })




  },




  created()
  {


  },


  data()
  {

   

    return {




      showOverlay: false,
      overlayMessage: "Cargando...",



      gridApi: null,



      CV12historicoComparePredictionsConfig: useApiServices.CV12historicoComparePredictionsConfig,
      CV12historicoComparePredictionsData: useApiServices.CV12historicoComparePredictionsData,

      cv12FetPredictionsChart: useApiServices.cv12FetPredictionsChart,
      cv12FeDttPredictionsChart: useApiServices.cv12FeDttPredictionsChart,
      cv12FeMagPredictionsChart: useApiServices.cv12FeMagPredictionsChart,

      stats: [],






    };
  },
};
      </script>
      <style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
    
    
    <style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
    
    <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
      