<template>
  <section>
    <b-tabs>
      <b-tab title="CV12">
        <CV12-tab></CV12-tab>
      </b-tab>

      <b-tab title="CV14">
        <CV14-tab></CV14-tab>
      </b-tab>
    </b-tabs>
  </section>
</template>
        
<script>


import CV12Tab from "./tabs/CV12Tab.vue"
import CV14Tab from "./tabs/CV14Tab.vue"

import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {

    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,


    BOverlay,
    BSpinner,

    CV12Tab,
    CV14Tab



  },

  methods: {



  },

  mounted()
  {


  },




  created()
  {

  },


  data()
  {

    return {
   };
  },

};

</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
      
      
      
<style lang="scss" >

@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
      
      <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        